<template>
  <div>
    <el-card v-loading="loadingFlag">
      <div>
        <el-button size="small" type="success" v-if="isShow" @click="isShow = false">编辑 </el-button>
        <div v-else>
          <el-button type="primary" size="small" @click="submit">保存 </el-button>
          <el-button @click="cancel" size="small">取消</el-button>
        </div>
      </div>
      <el-form ref="formRef" :model="editForm" label-width="120px" class="FormCl" :disabled="isShow">
        <el-form-item label="RFID：" size="small" prop="murf_no" :rules="[{ required: true, message: ' ' }]">
          <el-input ref="cardRef" v-model="editForm.murf_no" :disabled="editForm.murf_no.length === 10">
            <template slot="append">
              <el-link type="primary" class="vg_pointer" @click="emptyChange" disabled>清空 </el-link>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="模具编号：" size="small" prop="samp" :rules="[{ required: true, message: ' ' }]">
          <el-input v-model="editForm.mdnc_no" :disabled="true" placeholder="请选择模具编号">
            <template slot="append">
              <el-link type="primary" class="vg_pointer" @click="openSampDialog()" disabled>选择 </el-link>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="委托单号：" size="small" prop="entr" :rules="[{ required: true, message: ' ' }]">
          <el-input v-model="editForm.requ_no" disabled placeholder="请选择委托单号"></el-input>
        </el-form-item>
        <el-form-item label="开卡人姓名:" size="small" prop="stff_name" :rules="[{ required: true, message: ' ' }]">
          <el-input :disabled="true" v-model="editForm.stff_name"></el-input>
        </el-form-item>
        <el-form-item label="是否需要经历：">
          <el-checkbox v-model="editForm.procMap.设计" :disabled="editForm.procMap.设计Status">设计 </el-checkbox>
          <el-checkbox v-model="editForm.procMap.编程" :disabled="editForm.procMap.编程Status">编程 </el-checkbox>
          <el-checkbox v-model="editForm.procMap.钢材到位" :disabled="editForm.procMap.钢材到位Status">钢材到位 </el-checkbox>
          <el-checkbox v-model="editForm.procMap.模架到位" :disabled="editForm.procMap.模架到位Status">模架到位 </el-checkbox>
          <el-checkbox v-model="editForm.procMap.CNC" :disabled="editForm.procMap.CNCStatus">CNC </el-checkbox>
          <el-checkbox v-model="editForm.procMap.EDM" :disabled="editForm.procMap.EDMStatus">EDM </el-checkbox>
          <el-checkbox v-model="editForm.procMap.线切割" :disabled="editForm.procMap.线切割Status">线切割 </el-checkbox>
          <el-checkbox v-model="editForm.procMap.车床" :disabled="editForm.procMap.车床Status">车床 </el-checkbox>
          <el-checkbox v-model="editForm.procMap.省模" :disabled="editForm.procMap.省模Status">省模 </el-checkbox>
          <el-checkbox v-model="editForm.procMap.装配" :disabled="editForm.procMap.装配Status">装配 </el-checkbox>
          <el-checkbox v-model="editForm.procMap.表面处理" :disabled="editForm.procMap.表面处理Status">表面处理 </el-checkbox>
          <el-checkbox v-model="editForm.procMap.模具完成" :disabled="true">模具完成 </el-checkbox>
        </el-form-item>
      </el-form>

      <!-- 模具编号 -->
      <el-dialog :visible.sync="dialogSampVisible" width="70%" title="模具信息列表(未开卡)">
        <template>
          <el-form ref="form" :model="searchSampForm" label-width="100px">
            <el-row>
              <el-col :md="8">
                <el-form-item label="模具编号：">
                  <el-input v-model="searchSampForm.mdnc_no" size="small" placeholder="请输入模具编号" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="8">
                <el-form-item label="模具名称：">
                  <el-input v-model="searchSampForm.mdnc_name" size="small" placeholder="请输入模具名称" clearable></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="vg_mb_16">
              <el-col :md="8">
                <el-button class="vg_ml_16" type="primary" size="small" @click="searchSamp">搜索 </el-button>
                <el-button class="vg_mr_8" type="info" size="small" @click="searchSampO">刷新 </el-button>
                <el-link type="primary" @click="gosmpl" class="vg_cursor" icon="el-icon-edit-outline">模具总控表管理 </el-link>
              </el-col>
            </el-row>
            <div class="vg_mb_8"><i class="el-icon-info vg_mr_8"></i>{{ title }}</div>
            <el-table :data="SampList" border @cell-dblclick="sampDbClick" style="width: 100%" v-loading="loadingFlag" class="vg_pointer">
              <el-table-column type="index" label="序号" align="center"></el-table-column>
              <el-table-column prop="mdnc_no" label="模具编号"></el-table-column>
              <el-table-column prop="requ_no" label="委托编号"></el-table-column>
              <el-table-column prop="mdnc_name" label="模具名称"></el-table-column>
              <el-table-column
                prop="mdnc_part_type"
                label="模具类型"
                :formatter="({ mdnc_part_type }) => getMdncPartType(mdnc_part_type)"
              ></el-table-column>
              <el-table-column prop="mdnc_part_num" label="模具数量"></el-table-column>
              <el-table-column label="创建时间" prop="create_time" :formatter="({ create_time }) => getDateNoTime(create_time)" />
            </el-table>
            <pubPagination :totalPage="SampTotal" @changePageSearch="changeSampPageSearch" ref="pubPagination"></pubPagination>
          </el-form>
        </template>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { get, getNoCatch, post, postNoCatch } from '@api/request';
import { smplAPI } from '@api/modules/smpl'; // getSmpls
import { rfidAPI } from '@api/modules/rfid';
import helper from '@assets/js/helper.js';
import { muopAPI } from '@api/modules/muop';
import { getDateNoTime } from '@assets/js/dateUtils';
import { mmplAPI } from '@api/modules/mmpl';
import selectSectionType from '@/views/component/selectSectionType';
import pubPagination from '@/components/common/pubPagination';

export default {
  name: 'procEdit',
  data() {
    return {
      editForm: {
        murf_no: '',
        procMap: {
          设计: false,
          编程: false,
          钢材到位: false,
          模架到位: false,
          CNC: false,
          EDM: false,
          线切割: false,
          车床: false,
          省模: false,
          装配: false,
          表面处理: false,
          检验完成: false,
          模具完成: true
        }
      },
      dialogSampVisible: false,
      SampList: [], // 样品
      SampTotal: 0,
      searchSampForm: {
        page_no: 1
      },
      smpl_id: '',
      requ_id: '',
      isShow: true,
      title: ' 提示：(双击表编对应数据即可选中人员)',
      loadingFlag: true,
      loadFlag: true,
      selectRefuDisabled: false,
      mouldTypeList: [
        { id: 0, label: '生产模' },
        { id: 1, label: '样品模' }
      ]
    };
  },
  methods: {
    getMdncPartType(val) {
      return this.mouldTypeList.find(x => x.id === val)?.label ?? '暂无';
    },
    getDateNoTime,
    // 获取 卡数据
    getEditForm() {
      getNoCatch(muopAPI.getMurfById, {
        murf_id: this.$route.query.murf_id
      }).then(({ data }) => {
        // 转换工序对象
        let procMap = {};
        // TODO
        data.form.murfProcStatus.forEach((item, index) => {
          procMap[data.form.proc_order[index]] = item > 0;
          procMap[`${data.form.proc_order[index]}Status`] = item > 1;
        });
        this.editForm = data.form;
        this.editForm.procMap = procMap;
        setTimeout(() => {
          this.loadingFlag = false;
          this.isShowInputer = true;
        }, 500);
      });
    },
    // 获取模具数据
    getSampList() {
      getNoCatch(mmplAPI.getMmplsByNoMurf, this.searchSampForm).then(({ data }) => {
        this.SampList = data.list;
        this.SampTotal = data.total;
        setTimeout(() => {
          this.loadingFlag = false;
        }, 300);
      });
    },
    openSampDialog() {
      this.dialogSampVisible = true;
    },
    // 样品 双击获取数据
    sampDbClick(val) {
      this.smpl_id = val.smpl_id;
      this.editForm.smpl_no = val.smpl_no;
      this.editForm.requ_no = val.requ_no;
      this.requ_id = val.requ_id;
      this.editForm.belo_dept_id = val.belo_dept_id;
      this.editForm.belo_dept_name = val.belo_dept_name;
      this.dialogSampVisible = false;
    },
    changeSampPageSearch(val) {
      this.searchSampForm.page_no = val;
      this.getSampList();
    },
    searchSamp() {
      this.searchSampForm.page_no = 1;
      this.loadFlag = true;
      this.getSampList();
    },
    // 刷新
    searchSampO() {
      this.searchSampForm.page_no = 1;
      this.searchSampForm.smpl_no = null;
      this.searchSampForm.smpl_name = null;
      this.loadFlag = true;
      this.getSampList();
    },
    submit() {
      this.$confirm('是否修改卡?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        for (const key in this.editForm.procMap) {
          if (key.indexOf('Status') !== -1) {
            delete this.editForm.procMap[key];
          }
          if (this.editForm.procMap[key] < 2) {
            this.editForm.procMap[key] ? (this.editForm.procMap[key] = 1) : (this.editForm.procMap[key] = 0);
          }
        }
        postNoCatch(muopAPI.editMurf, this.editForm).then(({ data }) => {
          this.getEditForm();
          this.isShow = true;
        });
      });
    },
    cancel() {
      this.$confirm('是否取消修改, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.jump('muop_list');
      });
    },
    emptyChange() {
      this.editForm.murf_no = '';
      this.$nextTick(() => {
        this.$refs.cardRef.focus();
      });
    },
    // 跳转样品总控表
    gosmpl() {
      this.jump(`mmpl_list?perm_id=${this.helper.retPermId('mmpl')}`);
    }
  },
  created() {
    this.getEditForm();
  },
  components: {
    pubPagination,
    selectSectionType
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 10px;
}

.vd_tips {
  font-size: 12px;
  color: $color-info;
}
</style>
