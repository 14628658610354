import { render, staticRenderFns } from "./openEdit.vue?vue&type=template&id=327b02ae&scoped=true&"
import script from "./openEdit.vue?vue&type=script&lang=js&"
export * from "./openEdit.vue?vue&type=script&lang=js&"
import style0 from "./openEdit.vue?vue&type=style&index=0&id=327b02ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "327b02ae",
  null
  
)

export default component.exports